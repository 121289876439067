import React from 'react';
import { useRef ,useState } from 'react';
import { BsImageFill } from "react-icons/bs";


const UploadImage = () => {
    const inputRef = useRef(null);
    const [image , setImage] = useState("")
    const handleImageClick =()=>{ 
        inputRef.current.click();
    }
    const handleImageChange = (event) =>{
        // const file = event.target.files[0]
        setImage(event.target.files[0])
    }
  return (
    <div onClick={handleImageClick} style={{border:"2px #DEE2E6 dashed" ,padding:"5px 10px"}}>
        {image ?   <img style={{width:"20%"}} src={URL.createObjectURL(image)} alt="profile" /> :   <BsImageFill className='fs-2'/>}
       
        <input type="file" placeholder='Drop file or click to upload' onChange={handleImageChange} ref={inputRef} style={{position:'relative', left:"-180px", display:"none"}}/>
    </div>
  )
}

export default UploadImage