import React from "react";
import { BrowserRouter as Router, } from "react-router-dom";
import SideNavbar from "./components/SideNavbar";
import { InvoiceProvider } from "./InvoiceContext";

function App() {
  return (
    <Router>
      <InvoiceProvider>
        <SideNavbar />
      </InvoiceProvider>
    </Router>
  );
}

export default App;
